import { keyframes } from '@mui/styled-engine';
import { styled } from '@mui/material/styles';

/**
 * ボタン系
 */

// キーフレームの定義
const shineAnimation = keyframes`
  100% {
    left: 100%;
  }
`;

// ボタンのスタイルを定義
export const Shine = styled('a')(({ theme }) => ({
        display: 'inline-block',
        width: '300px',
        maxWidth: '90%',
        cursor: 'pointer',
        fontSize: '20px',
        padding: '10px 10px',
        background: '#cf1111',
        textAlign: 'center',
        textDecoration: 'none',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '-100%',
        backgroundImage: 'linear-gradient(130deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 75%)',
    },
    '&:hover::before': {
        animation: `${shineAnimation} 0.5s`,
    },
}));


interface DownloadButtonProps {
    id: string;
    label: string;
    file: string;
    fileName: string;
  }
export function DownloadButton({id, label, file, fileName}: DownloadButtonProps){
  
    const CaptionA = styled("a")(({ theme }) => ({
      fontSize: "2.0rem",
      fontWeight: "900",
      textAlign: "center",
      color: 'white',
      letterSpacing: '0.5rem',
      textDecoration:'underline',
    }));
      
      // 新しいスタイルを定義
    const styleOverwrap = {
      maxWidth: "300px",
      margin: "auto",
    };
  
    // 新しいスタイルを追加したShineコンポーネント
    const ShineButton = styled(Shine)(styleOverwrap);
  
    return (
      <ShineButton id={id}>
        <CaptionA href={file} download={fileName}>
            {label}
        </CaptionA>
      </ShineButton>
    );
  };
  