import { Headline, ParentSecondary, ParentItem } from "templates/styled";

/**
 * 説明文
 * @returns 
 */
interface ExplainProps {
    id: string;
    headline: string;
    captions: string[];
}
export function ExplainA({id, headline, captions}: ExplainProps){

    return (
        <ParentSecondary id={id}>
            <Headline>{headline}</Headline>
            <hr />
            <ParentItem>
                <p className="caption">
                {captions.map((caption, index) => (
                    <span key={index}>{caption}</span>
                ))}
                </p>
            </ParentItem>
        </ParentSecondary>
    );

}
  
/*

    const Item = styled("div")(({ theme }) => ({
        placeItems: "center",
        letterSpacing: "0.3em",
        width: "100%",
        display: "flex",
        flexFlow: "column",
        gap: theme.spacing(1),
        "& h3": theme.typography.h3,
        "& .label": theme.typography.subtitle1,
        "& .name span:nth-of-type(1):after": {
        content: "'/'",
        marginLeft: "1rem",
        marginRight: "1rem",
        },
        "& .caption":
        (theme.typography.caption, { "& span": { display: "block" } }),
    }));
    */