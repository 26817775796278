import { styled } from "@mui/material/styles";

export function TriangleAnimation({
  link,
  label,
  style,
}: {
  link: string;
  label: string;
  style?: any;
}) {
  const AnkerLink = styled("a")(({ theme }) => ({
    ...theme.typography.caption,
    textDecoration: "none",
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "row",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    "& *": {
      fontWeight: "700",
    },
    "& .label": {
      display: "block",
      alignItems: "center",
    },
    "& .triangle": {
      transform: "rotateZ(-90deg)",
      display: "block",
    },
    "&:hover .triangle": {
      // マウスホバー時のアニメーション
      animationName: "triangleAnimation",
      animationDuration: "1s",
      animationFillMode: "backwards",
      animationIterationCount: "infinite",
      animationTimingFunction: "ease",
    },
    "@keyframes triangleAnimation": {
      // アニメーションの定義
      "0%": {
        transform: "rotateX(0deg) rotateZ(-90deg)",
      },
      "100%": {
        transform: "rotateX(360deg) rotateZ(-90deg)",
      },
    },
    ...style,
  }));

  return (
    <AnkerLink href={link}>
      <span className="label">{label}</span>
      <span className="triangle">▼</span>
    </AnkerLink>
  );
}

export function LeftIcon({
  link,
  icon,
  label,
  style,
  ...props
}: {
  link: string;
  icon: string;
  label: string;
  style?: any;
  props?: any;
}) {
  const IconLink = styled("a")(({ theme }) => ({
    ...theme.typography.caption,
    textDecoration: "none",
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& *": {
      margin: "unset",
      fontWeight: "700",
      color: 'inherit',
    },
    "& img": {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    ...style,
  }));
  return (
    <IconLink href={link} {...props}>
      <img src={icon} alt="" />
      <p>{label}</p>
    </IconLink>
  );
}
