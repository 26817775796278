import { styled } from "@mui/material/styles";


// タイトル
export const Headline = styled("h2")(({ theme }) => ({
    ...theme.typography.h2,
    maxWidth: "80%",
    fontSize: "1.5rem",
}));

// サブタイトル
export const SubTitle = styled("h3")(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: "900",
    fontSize: "1.2rem",
}));
    
/**
 * Parentで囲まれたコンポーネント
 */
export const ParentPrimary = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    "& hr": {
      width: "100%",
      borderColor: theme.palette.primary.main,
    },
    "& h3": {
      color: theme.palette.text.primary,
    },
    "& .caption": {
      ...theme.typography.caption,
      overflowWrap: "break-word",
    },
}));
  
export const ParentSecondary = styled(ParentPrimary)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "& hr": {
      borderColor: theme.palette.secondary.contrastText,
    },
}));

// 画像とキャプションを表示するコンポーネント
export const ParentItem = styled("div")(({ theme }) => ({
    placeItems: "center",
    letterSpacing: "0.3em",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(1),
    "& h3": theme.typography.h3,
    "& .label": theme.typography.subtitle1,
    "& .name span:nth-of-type(1):after": {
      content: "'/'",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .caption":
      (theme.typography.caption, { "& span": { display: "block" } }),
}));

/**
 * サムネと説明文を枠で囲ったものを表示するコンポーネント
 */
export const ListItems = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    "& .item": {
      display: "flex",
      flexFlow: "rows",
      gap: theme.spacing(4),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(4),
      "& img": {
        width: "100%",
        maxWidth: "150px",
        borderRadius: theme.shape.borderRadius,
      },
      "& .caption": {
        ...theme.typography.caption, 
        color: theme.palette.primary.main,
        fontWeight:"900",
        "& span": {
          display: "block",
        },
      },
    },
}));

/**
 * テーブルを表示するコンポーネント
 */
export const Table = styled("table")(({ theme }) => ({
    width: "100%",
    tableLayout: 'fixed',
    textAlign: "center",
    borderCollapse: "collapse",
    border: `1px solid ${theme.palette.primary.contrastText}`,
    "& *": {
      border: `1px solid ${theme.palette.primary.contrastText}`,
    },
    "& thead": {
      backgroundColor: 'rgba(237,119,0,0.9)',
      color: theme.palette.primary.contrastText,
    },
    "& .caption": {
      ...theme.typography.caption,
      display: "flex",
      justifyContent: "space-between",
    },
}));
