import Smaphon2Col, { MainWidth } from "templates/Smaphon2Col";
import { styled } from "@mui/material/styles";
import kv from "assets/img/kv.png";
import caption01 from "assets/img/caption01.png";
import feature01 from "assets/img/feature01.png";
import feature02 from "assets/img/feature02.png";
import feature03 from "assets/img/feature03.png";
import { SectionA } from "components/Section";
import { ExplainA } from "components/Explain";
import { ListA } from "components/List";
import { FeeTable } from "components/Table";
import { DownloadButton } from "components/Button";

const document = 'pdf/トトノウCMS+_ITツール申請書類.pdf';

export default function Main() {
  return (
    <Smaphon2Col>
      <Kv 
        imgAlt="トトノウMA＋"
        imgSrc={kv}
        headline="トトノウMA＋"
        captions={[
          'お客様を知るツール',
        ]}
      />
      <ExplainA
        id={'about'}
        headline={'トトノウMA＋とは？'}
        captions={[
          'お客様がいつ何に興味を持っているのか、お客様を知るためのMAツールです。',
          'お客様のWEBサイトを訪れた人がどこから来て何を見ていたのかを可視化します。日、週、月別のユーザ数の増減に加え、曜日や祝日別の比較、時間別の比較も行えます。これによりシフトコントロールやサーバのスケーリング予約などに役立てることが出来ます。',
        ]}
      />
      <SectionA 
        id={'features'}
        headline={'トトノウMA＋の特徴'}
        captions={[
          '最新情報をリアルタイムで提供し、直感的なグラフでデータを視覚化、さらにカスタマージャーニーを追跡してサイト改善に役立てることができます。',
        ]}
        imgSrc={caption01}
      />
      <ListA
        id={''}
        headline={'その他にもこんなことが！'}
        list={[
          {
            imgSrc: feature01,
            imgAlt: '特徴01',
            captions: [
              'サイト別に数値をグラフにして表示しています。',
              '直感的でわかりやすさを優先し、すぐに現状を把握することができます。',
            ],
          },
          {
            imgSrc: feature02,
            imgAlt: '特徴02',
            captions: [
              '情報はリアルタイム更新です。',
              '常に最新を知ることで、ビジネスチャンスを逃しません。',
            ],
          },
          {
            imgSrc: feature03,
            imgAlt: '特徴03',
            captions: [
              'ユーザの行動を見れるリアルカスタマージャーニー機能を搭載。',
              'お客様の行動を把握し、サイトの改善に役立てることができます。',
            ],
          },
        ]}
      />
      <FeeTable
        id={'fee'}
        headline="料金について"
        tables={[
          {
            subTitle: '月額',
            head: ['1サイト','5サイト目以降'],
            body: ['¥10,000', '¥5,000-'],
          },
        ]}
        captions={[
          '例：7サイト）',
          '10,000 * 5 + 5,000 * 2 = 55,000円',
          '※初期設定などが必要な場合、別途料金がかかります。',
        ]}
      />
      <DownloadButton
        id='download'
        label='資料はこちら'
        file={document}
        fileName='トトノウMA＋.pdf'
      />
    </Smaphon2Col>
  );
}

interface KvProps {
  imgSrc: string;
  imgAlt: string;
  headline: string;
  captions: string[];
}
function Kv({ imgSrc, imgAlt, headline, captions}: KvProps) {
  const DivKv = styled("div")(({ theme }) => ({
    position: "relative",
    maxWidth: "380px",
    margin: "auto",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(4),
    "& img": {
      width: "100%",
      display: "block",
    },
  }));

  const Headline = styled("h1")(({ theme }) => ({
    ...theme.typography.h1,
    maxWidth: "80%",
    margin: "auto",
    "& .logo-img": {},
  }));

  const Catchcopy = styled("p")(({ theme }) => ({
    fontSize: "1.5rem",
    fontWeight: "100",
    textAlign: "center",
    margin: "auto",
    letterSpacing: "0.3em",
    fontFamily: "M PLUS Rounded 1c, sans-serif",
    "& span": {
      textAlign: "left",
    },
  }));

  return (
    <DivKv id="kv">
        <img src={imgSrc} alt={imgAlt} />
        <Headline>
            <span>{headline}</span>
        </Headline>
        <Catchcopy content="subtitle1">
          {captions.map((caption, index) => (
            <span key={index}>{caption}</span>
          ))}
        </Catchcopy>
    </DivKv>
  );
}
