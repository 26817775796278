import { Headline, ParentSecondary, ListItems } from 'templates/styled';

/**
 * 
 * @returns 特徴
 */
interface ListProps {
    id: string;
    headline: string;
    list: {
      imgSrc?: string | null;
      imgAlt?: string;
      captions: string[];
    }[];
}
export function ListA({id, headline, list}: ListProps){

    return (
      <ParentSecondary id={id}>
        <Headline>{headline}</Headline>
        <hr />
        <ListItems>
          {list.map((item, index) => (
            <div key={index} className='item'>
              {item.imgSrc && <img src={item.imgSrc} alt={item.imgAlt} />}
              <p className="caption">
                {item.captions.map((caption, index) => (
                  <span key={index}>{caption}</span>
                ))}
              </p>
            </div>
          ))}
        </ListItems>
      </ParentSecondary>
    );
  
  }