import { Headline, SubTitle, ParentPrimary, Table, ParentItem } from "templates/styled";

interface FeeTableProps {
    id: string;
    headline: string;
    tables: {
      subTitle: string;
      head: string[];
      body: string[];
    }[];
    captions: string[];
}
export function FeeTable({id, headline, tables, captions}: FeeTableProps){
  
    return (
      <ParentPrimary id={id}>
        <Headline>{headline}</Headline>
        <hr />
  
        {tables.map((table, index) => (
          <>
            <SubTitle>{table.subTitle}</SubTitle>
            <Table key={index}>
              <thead>
                <tr>
                  {table.head.map((head, index) => (
                    <th key={index}>{head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {table.body.map((body, index) => (
                    <td key={index}>{body}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </>
        ))}
  
        <ParentItem className="item">
          <p className="caption">
            {captions.map((caption, index) => (
              <span key={index}>{caption}</span>
            ))}
          </p>
        </ParentItem>
      </ParentPrimary>
    );
}
  