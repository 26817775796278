import { createTheme } from "@mui/material/styles";

export const color = {
  primary: "#32BEC8",
  secondary: "#222222",
  white: "#FFFFFF",
  black: "#000000",
};

// カスタムテーマの定義
export const theme = createTheme({
  palette: {
    primary: {
      main: color.white, // メインのプライマリカラー
      contrastText: color.secondary,
    },
    secondary: {
      main:  color.primary, // セカンダリカラー
      contrastText: color.white, // セカンダリカラー
    },
    background: {
      default: "rgba(255, 255, 255, 0.95)", // デフォルトの背景色
    },
    text:{
      primary: color.black,
      secondary: color.primary,
      disabled: color.secondary,
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif", // テーマのデフォルトフォント
    h1: {
      fontSize: "2.5rem", // ヘッダー1のフォントサイズ
      fontWeight: "900", // ヘッダー1のフォントウェイト
      objectFit: "contain",
      textAlign: "center",
      border: "unset",
      color: color.primary,
    },
    h2: {
        fontSize: "1.5rem", // ヘッダー2のフォントサイズ
        objectFit: 'contain',
        textAlign: 'center',
        border: 'unset',
        margin: 'auto',
        "& img": {
          width: "100%",
        },
    },
    h3:{
        fontSize: "1.2rem", // ヘッダー2のフォントサイズ
        margin:'unset',
    },
    subtitle1:{
        fontSize: "1.2rem", // ヘッダー2のフォントサイズ
        margin:'unset',
        fontWeight: '800',
    },
    caption:{
        fontSize: '1.0rem',
        fontWeight: '300',
        margin:'none',
    }
  },
  spacing: 8, // スペースの基本単位
  shape: {
    borderRadius: 10, // ボーダーの角丸のサイズ
  },
  breakpoints: {
    values: {
      xs: 0, // モバイルデバイス
      sm: 600, // タブレットデバイス
      md: 960, // 小さいデスクトップデバイス
      lg: 1280, // 大きいデスクトップデバイス
      xl: 1920, // エクストララージデスクトップデバイス
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: "64px", // ヘッダーの高さ
          backgroundColor: "#000000", // ヘッダーの背景色を黒色に設定
          position: "unset", // デフォルトはunset
          "@media (min-width: 600px)": {
            position: "sticky", // 600px以上の場合はfixed
          },
        },
      },
    },
    
  },
  // 他のテーマオプションも必要に応じて追加
});

export const themeFooter = {
  backgroundColor: color.secondary,
  color: color.white,
  gap: theme.spacing(3),
}

export const themeCopyright = {
  backgroundColor: color.white,
  color: color.secondary,
  padding: theme.spacing(3),
}

