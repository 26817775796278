import { styled } from "@mui/material/styles";

export function Address() {
  const Address = styled("div")(({ theme }) => ({
    ...theme.typography.caption,
    display: "flex",
    gap: theme.spacing(1),
    flexFlow: "column",
    "& p": {
      margin: "unset",
      fontSize: "1.0rem",
      "& span": {
        display: "block",
      },
    },
    "& p.post-num:before": {
      content: '"〒"',
    },
  }));

  const Link = styled("a")(({ theme }) => ({
    ...theme.typography.caption,
    textDecoration: "none",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& *": {
      margin: "unset",
      fontWeight: "700",
    },
  }));

  
  return (
    <Address className="address">
      <Link href='https://addonem.com?route=ma-p.ttnou.com'>https://addonem.com</Link>
      <Link href='mailto:ttnou@addonem.com'>ttnou@addonem.com</Link>
    </Address>
  );
}
