import { Headline, ParentPrimary, ParentItem } from "templates/styled";

/**
 * システムの説明
 * @returns 
 */
interface SectionAProps {
    id: string;
    headline: string;
    captions: string[];
    imgSrc: string;
    imgAlt?: string;
}
export function SectionA({id, headline, captions, imgSrc, imgAlt}: SectionAProps){
  
  return (
    <ParentPrimary id={id}>
      <Headline>{headline}</Headline>
      <hr />
      <ParentItem>
        <p className="caption">
          {captions.map((caption, index) => (
            <span key={index}>{caption}</span>
          ))}
        </p>
      </ParentItem>
      <img id="caption-img" src={imgSrc} alt={imgAlt} />
    </ParentPrimary>
  );
}
